import React from 'react'
import styled from "styled-components";
import { Try } from '../Try/Try';

export default function Message({nome,hora,message}:any) {

	const allnames = nome.split(' ');
	const shortName = allnames.length > 1 ? `${allnames[0][0]}${allnames[allnames.length - 1][0]}` : allnames[0].slice(0,2);

	return (
		<Try>
		<Container>
			<BallContainer>
				<Ball>{shortName}</Ball>
			</BallContainer>
			<LineContainer style={{}}>
				<Line1 >
					<Name>{nome}</Name>
					<Time>{hora}</Time>
				</Line1>
				<Line2 >
					<Msg>
						{message}
					</Msg>
				</Line2>
			</LineContainer>
		</Container>
		</Try>
	)
}

const BallContainer = styled.div`
	display:flex;
	align-items:center;
`

const Ball = styled.div`
	background-color:#E31B16;
	min-height:40px;
	min-width:40px;
	max-height:40px;
	max-width:40px;
	display:flex;
	justify-content:center;
	align-items:center;
	color:white;
	text-transform:uppercase;
	margin:10px;
	border-radius:50%;
	font-size:16px;
`

const Name = styled.div`
	font-size:12px;
	color:#757575;
`
const Time = styled.div`
	font-size:10px;
	color:#757575;
`

const Msg = styled.div`
	color:black;
	font-size:16px;
	line-height:20px;
	padding-bottom:5px;
	border-bottom:1px solid #757575;
`

const Line1 = styled.div`
	padding-bottom:5px;
	display:flex;
	width:100%;
	justify-content:space-between;
`

const Line2 = styled.div`

`
const LineContainer = styled.div`
	width:100%;
	margin:10px;
	border-bottom: 1px solid ${props => props.theme.DarkBlueTim};
`

const Container = styled.div`
	display:flex;
	width:100%;
	flex-grow:1;
	font-weight:400;
`



