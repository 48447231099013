import { createGlobalStyle } from 'styled-components'

import GothamBlackEot from './Gotham-Black.eot'
import GothamBlackWoff2 from './Gotham-Black.woff2'
import GothamBlackWoff from './Gotham-Black.woff'
import GothamBlackTTF from './Gotham-Black.ttf'
import GothamBlackSVG from './Gotham-Black.svg'

import GothamBoldItalicEot from './Gotham-BoldItalic.eot'
import GothamBoldItalicWoff2 from './Gotham-BoldItalic.woff2'
import GothamBoldItalicWoff from './Gotham-BoldItalic.woff'
import GothamBoldItalicTTF from './Gotham-BoldItalic.ttf'
import GothamBoldItalicSVG from './Gotham-BoldItalic.svg'

import GothamBlackItalicEot from './Gotham-BlackItalic.eot'
import GothamBlackItalicWoff2 from './Gotham-BlackItalic.woff2'
import GothamBlackItalicWoff from './Gotham-BlackItalic.woff'
import GothamBlackItalicTTF from './Gotham-BlackItalic.ttf'
import GothamBlackItalicSVG from './Gotham-BlackItalic.svg'

import GothamBoldEot from './Gotham-Bold.eot'
import GothamBoldWoff2 from './Gotham-Bold.woff2'
import GothamBoldWoff from './Gotham-Bold.woff'
import GothamBoldTTF from './Gotham-Bold.ttf'
import GothamBoldSVG from './Gotham-Bold.svg'

import GothamBookItalicEot from './Gotham-BookItalic.eot'
import GothamBookItalicWoff2 from './Gotham-BookItalic.woff2'
import GothamBookItalicWoff from './Gotham-BookItalic.woff'
import GothamBookItalicTTF from './Gotham-BookItalic.ttf'
import GothamBookItalicSVG from './Gotham-BookItalic.svg'

import GothamBookEot from './Gotham-Book.eot'
import GothamBookWoff2 from './Gotham-Book.woff2'
import GothamBookWoff from './Gotham-Book.woff'
import GothamBookTTF from './Gotham-Book.ttf'
import GothamBookSVG from './Gotham-Book.svg'

import GothamLightItalicEot from './Gotham-LightItalic.eot'
import GothamLightItalicWoff2 from './Gotham-LightItalic.woff2'
import GothamLightItalicWoff from './Gotham-LightItalic.woff'
import GothamLightItalicTTF from './Gotham-LightItalic.ttf'
import GothamLightItalicSVG from './Gotham-LightItalic.svg'

import GothamLightEot from './Gotham-Light.eot'
import GothamLightWoff2 from './Gotham-Light.woff2'
import GothamLightWoff from './Gotham-Light.woff'
import GothamLightTTF from './Gotham-Light.ttf'
import GothamLightSVG from './Gotham-Light.svg'

import GothamThinEot from './Gotham-Thin.eot'
import GothamThinWoff2 from './Gotham-Thin.woff2'
import GothamThinWoff from './Gotham-Thin.woff'
import GothamThinTTF from './Gotham-Thin.ttf'
import GothamThinSVG from './Gotham-Thin.svg'

import GothamMediumEot from './Gotham-Medium.eot'
import GothamMediumWoff2 from './Gotham-Medium.woff2'
import GothamMediumWoff from './Gotham-Medium.woff'
import GothamMediumTTF from './Gotham-Medium.ttf'
import GothamMediumSVG from './Gotham-Medium.svg'

import GothamMediumItalicEot from './Gotham-MediumItalic.eot'
import GothamMediumItalicWoff2 from './Gotham-MediumItalic.woff2'
import GothamMediumItalicWoff from './Gotham-MediumItalic.woff'
import GothamMediumItalicTTF from './Gotham-MediumItalic.ttf'
import GothamMediumItalicSVG from './Gotham-MediumItalic.svg'

import GothamThinItalicEot from './Gotham-ThinItalic.eot'
import GothamThinItalicWoff2 from './Gotham-ThinItalic.woff2'
import GothamThinItalicWoff from './Gotham-ThinItalic.woff'
import GothamThinItalicTTF from './Gotham-ThinItalic.ttf'
import GothamThinItalicSVG from './Gotham-ThinItalic.svg'

import GothamUltraEot from './Gotham-Ultra.eot'
import GothamUltraWoff2 from './Gotham-Ultra.woff2'
import GothamUltraWoff from './Gotham-Ultra.woff'
import GothamUltraTTF from './Gotham-Ultra.ttf'
import GothamUltraSVG from './Gotham-Ultra.svg'

import GothamUltraItalicEot from './Gotham-UltraItalic.eot'
import GothamUltraItalicWoff2 from './Gotham-UltraItalic.woff2'
import GothamUltraItalicWoff from './Gotham-UltraItalic.woff'
import GothamUltraItalicTTF from './Gotham-UltraItalic.ttf'
import GothamUltraItalicSVG from './Gotham-UltraItalic.svg'

import GothamExtraLightItalicEot from './Gotham-ExtraLightItalic.eot'
import GothamExtraLightItalicWoff2 from './Gotham-ExtraLightItalic.woff2'
import GothamExtraLightItalicWoff from './Gotham-ExtraLightItalic.woff'
import GothamExtraLightItalicTTF from './Gotham-ExtraLightItalic.ttf'
import GothamExtraLightItalicSVG from './Gotham-ExtraLightItalic.svg'

import GothamExtraLightEot from './Gotham-ExtraLight.eot'
import GothamExtraLightWoff2 from './Gotham-ExtraLight.woff2'
import GothamExtraLightWoff from './Gotham-ExtraLight.woff'
import GothamExtraLightTTF from './Gotham-ExtraLight.ttf'
import GothamExtraLightSVG from './Gotham-ExtraLight.svg'


export default createGlobalStyle`

@font-face {
    font-family: 'Gotham';
    src: url(${GothamBlackEot});
    src: url(${GothamBlackEot}?#iefix) format('embedded-opentype'),
        url(${GothamBlackWoff2}) format('woff2'),
        url(${GothamBlackWoff}) format('woff'),
        url(${GothamBlackTTF}) format('truetype'),
        url(${GothamBlackSVG}#Gotham-Black) format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'Gotham';
    src: url(${GothamBoldItalicEot});
    src: url(${GothamBoldItalicEot}?#iefix) format('embedded-opentype'),
        url(${GothamBoldItalicWoff2}) format('woff2'),
        url(${GothamBoldItalicWoff}) format('woff'),
        url(${GothamBoldItalicTTF}) format('truetype'),
        url(${GothamBoldItalicSVG}#Gotham-BoldItalic) format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: fallback;
}

@font-face {
    font-family: 'Gotham';
    src: url(${GothamBlackItalicEot});
    src: url(${GothamBlackItalicEot}?#iefix) format('embedded-opentype'),
        url(${GothamBlackItalicWoff2}) format('woff2'),
        url(${GothamBlackItalicWoff}) format('woff'),
        url(${GothamBlackItalicTTF}) format('truetype'),
        url(${GothamBlackItalicSVG}#Gotham-BlackItalic) format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: fallback;
}

@font-face {
    font-family: 'Gotham';
    src: url(${GothamBoldEot});
    src: url(${GothamBoldEot}?#iefix) format('embedded-opentype'),
        url(${GothamBoldWoff2}) format('woff2'),
        url(${GothamBoldWoff}) format('woff'),
        url(${GothamBoldTTF}) format('truetype'),
        url(${GothamBoldSVG}#Gotham-Bold) format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'Gotham Book';
    src: url(${GothamBookItalicEot});
    src: url(${GothamBookItalicEot}?#iefix) format('embedded-opentype'),
        url(${GothamBookItalicWoff2}) format('woff2'),
        url(${GothamBookItalicWoff}) format('woff'),
        url(${GothamBookItalicTTF}) format('truetype'),
        url(${GothamBookItalicSVG}#Gotham-BookItalic) format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: fallback;
}

@font-face {
    font-family: 'Gotham Book';
    src: url(${GothamBookEot});
    src: url(${GothamBookEot}?#iefix) format('embedded-opentype'),
        url(${GothamBookWoff2}) format('woff2'),
        url(${GothamBookWoff}) format('woff'),
        url(${GothamBookTTF}) format('truetype'),
        url(${GothamBookSVG}#Gotham-Book) format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'Gotham';
    src: url(${GothamLightItalicEot});
    src: url(${GothamLightItalicEot}?#iefix) format('embedded-opentype'),
        url(${GothamLightItalicWoff2}) format('woff2'),
        url(${GothamLightItalicWoff}) format('woff'),
        url(${GothamLightItalicTTF}) format('truetype'),
        url(${GothamLightItalicSVG}#Gotham-LightItalic) format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: fallback;
}

@font-face {
    font-family: 'Gotham';
    src: url(${GothamLightEot});
    src: url(${GothamLightEot}?#iefix) format('embedded-opentype'),
        url(${GothamLightWoff2}) format('woff2'),
        url(${GothamLightWoff}) format('woff'),
        url(${GothamLightTTF}) format('truetype'),
        url(${GothamLightSVG}#Gotham-Light) format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'Gotham';
    src: url(${GothamThinEot});
    src: url(${GothamThinEot}?#iefix) format('embedded-opentype'),
        url(${GothamThinWoff2}) format('woff2'),
        url(${GothamThinWoff}) format('woff'),
        url(${GothamThinTTF}) format('truetype'),
        url(${GothamThinSVG}#Gotham-Thin) format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'Gotham';
    src: url(${GothamMediumEot});
    src: url(${GothamMediumEot}?#iefix) format('embedded-opentype'),
        url(${GothamMediumWoff2}) format('woff2'),
        url(${GothamMediumWoff}) format('woff'),
        url(${GothamMediumTTF}) format('truetype'),
        url(${GothamMediumSVG}#Gotham-Medium) format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'Gotham';
    src: url(${GothamMediumItalicEot});
    src: url(${GothamMediumItalicEot}?#iefix) format('embedded-opentype'),
        url(${GothamMediumItalicWoff2}) format('woff2'),
        url(${GothamMediumItalicWoff}) format('woff'),
        url(${GothamMediumItalicTTF}) format('truetype'),
        url(${GothamMediumItalicSVG}#Gotham-MediumItalic) format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: fallback;
}

@font-face {
    font-family: 'Gotham';
    src: url(${GothamThinItalicEot});
    src: url(${GothamThinItalicEot}?#iefix) format('embedded-opentype'),
        url(${GothamThinItalicWoff2}) format('woff2'),
        url(${GothamThinItalicWoff}) format('woff'),
        url(${GothamThinItalicTTF}) format('truetype'),
        url(${GothamThinItalicSVG}#Gotham-ThinItalic) format('svg');
    font-weight: 100;
    font-style: italic;
    font-display: fallback;
}

@font-face {
    font-family: 'Gotham Ultra';
    src: url(${GothamUltraEot});
    src: url(${GothamUltraEot}?#iefix) format('embedded-opentype'),
        url(${GothamUltraWoff2}) format('woff2'),
        url(${GothamUltraWoff}) format('woff'),
        url(${GothamUltraTTF}) format('truetype'),
        url(${GothamUltraSVG}#Gotham-Ultra) format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'Gotham Ultra';
    src: url(${GothamUltraItalicEot});
    src: url(${GothamUltraItalicEot}?#iefix) format('embedded-opentype'),
        url(${GothamUltraItalicWoff2}) format('woff2'),
        url(${GothamUltraItalicWoff}) format('woff'),
        url(${GothamUltraItalicTTF}) format('truetype'),
        url(${GothamUltraItalicSVG}#Gotham-UltraItalic) format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: fallback;
}

@font-face {
    font-family: 'Gotham Extra';
    src: url(${GothamExtraLightItalicEot});
    src: url(${GothamExtraLightItalicEot}?#iefix) format('embedded-opentype'),
        url(${GothamExtraLightItalicWoff2}) format('woff2'),
        url(${GothamExtraLightItalicWoff}) format('woff'),
        url(${GothamExtraLightItalicTTF}) format('truetype'),
        url(${GothamExtraLightItalicSVG}#Gotham-ExtraLightItalic) format('svg');
    font-weight: 200;
    font-style: italic;
    font-display: fallback;
}

@font-face {
    font-family: 'Gotham Extra';
    src: url(${GothamExtraLightEot});
    src: url(${GothamExtraLightEot}?#iefix) format('embedded-opentype'),
        url(${GothamExtraLightWoff2}) format('woff2'),
        url(${GothamExtraLightWoff}) format('woff'),
        url(${GothamExtraLightTTF}) format('truetype'),
        url(${GothamExtraLightSVG}#Gotham-ExtraLight) format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: fallback;
}



`