import styled from "styled-components";

const Center = styled.div<{width?:number}>`
	display:flex;
	flex-direction:column;
	justify-items:center;
	align-content:flex-start;
	justify-self:center;
	align-self:center;
	max-width:${props => props.width?props.width:1250}px;
	height:100%;
	width:100%;
	min-width:300px;
	min-height: 100vh;
`

export default Center;