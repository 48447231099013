import { Component } from "react";

export class Try extends Component {
	state: any;
	constructor(props: any) {
		super(props);
		this.state = { hasError: false };
	}

	componentDidCatch(error: any, info: any) {
		this.setState({ hasError: true });
		console.error("graceful error", error, info);
	}


	render() {
		if (this.state.hasError) {
			return <></>;
		}
		return this.props.children;
	}

}