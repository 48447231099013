import React, { CSSProperties } from 'react'

import {ReactComponent as SetaSVG} from './SVGs/Seta.svg'
import {ReactComponent as CloseSVG} from './SVGs/Close.svg'
import {ReactComponent as BarsSolidSVG} from './SVGs/bars-solid.svg'

import Img16x9PNG from './images/16x9.png'
import BackgroundJPG from './images/background_e.jpg'
import BackgroundSobreJPG from './images/background_sobre.jpg'
import MovimentoPNG from './images/movimento.png'
import LogoPNG from './images/Logo.png'
import LoginRelogioJPG from './images/login_relogio.jpg'
import MiddleTextPNG from './images/MiddleText.png'
import BottomTextPNG from './images/BottomText.png'
import ComingSoonPNG from './images/coming_soon.png'

// SVG
export const MenuIcon = () => <BarsSolidSVG />
export const Seta = () => <SetaSVG fill={'#bf0c3d'} stroke={'#bf0c3d'}/>
export const Close = () => <CloseSVG fill={'#bf0c3d'} stroke={'#bf0c3d'}/>

// images
export const Img16x9 = ({style}:{style?:CSSProperties}) => <img style={{...style}} className="p_16x9" src={Img16x9PNG} alt="placeholder video"/>
export const ImgDefaultPlenaria = ({style}:{style?:CSSProperties}) => <img style={{...style}} className="defaultPlenaria" src={ComingSoonPNG} alt="placeholder plenaria"/>
export const Background = ({style}:{style?:CSSProperties}) => <img style={{...style}} className="background" src={BackgroundJPG} alt="background"/>
export const Background_sobre = ({style}:{style?:CSSProperties}) => <img style={{...style}} className="background_sobre" src={BackgroundSobreJPG} alt="background_sobre"/>
export const Movimento = ({style}:{style?:CSSProperties}) => <img style={{...style}} className="movimento" src={MovimentoPNG} alt="movimento"/>
export const LogoHome = ({style}:{style?:CSSProperties}) => <img style={{...style}} className="technos" src={LogoPNG} alt="technos logo"/>
export const RelogioHome = ({style}:{style?:CSSProperties}) => <img style={{...style}} className="technos_relogio" src={LoginRelogioJPG} alt="technos relogio"/>
export const MiddleText = ({style}:{style?:CSSProperties}) => <img style={{...style}} className="technos_relogio" src={MiddleTextPNG} alt="vem ai o smartwatch mais esperado do ano"/>
export const BottomText = ({style}:{style?:CSSProperties}) => <img style={{...style}} className="technos_relogio" src={BottomTextPNG} alt="conexão para voce ir alem"/>
