
import React from 'react';
import { useDispatch } from 'react-redux';
import { Route, Redirect, useLocation } from "react-router-dom";
import redirectToState from '../../store/slices/redirectTo';
import { GuardProps } from './GuardedRouteType';


const GuardedRoute = ({ children,path, permission, redirect, ...rest }: GuardProps) => {
	const dispatch = useDispatch()
	const { pathname } = useLocation();
	dispatch(redirectToState.actions.changeRedirect(pathname));
	let redirectPath = "/";
	if(!permission && typeof redirect === "function") redirectPath = redirect();
	if(!permission && typeof redirect === "string") redirectPath = redirect; 
	return (<Route path={path} {...rest} render={(props) => (
		permission === true
			? <>{children}</>
			: <Redirect to={redirectPath} />
	)} />)
}
export default GuardedRoute;