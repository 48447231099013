import { createSlice } from '@reduxjs/toolkit'

const speakerState = createSlice({
  name: 'speakerState',
  initialState: {
	dados:[]
  },
  reducers: {
    changeSpeaker: (state,action:any) => {
		const speaker = action.payload;
		state.dados=speaker;
	},
  }
})

export const getSpeakerState = (state:any) => state[speakerState.name]

export default speakerState
