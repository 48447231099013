import { createSlice } from '@reduxjs/toolkit'

const loginState = createSlice({
  name: 'loginState',
  initialState: {
	logado:false,
	token:null,
	realizouCadastro:false,
	nome:null,
	email:null,
	guid:null,
  },
  reducers: {
    login: (state,action:any) => {
		const {token,realizouCadastro,nome,email,guid} = action.payload;
		 state.nome=nome;
		 state.logado = true; 
		 state.token = token;
		 state.realizouCadastro = realizouCadastro;
		 state.email = email;
		 state.guid = guid;
		 
	},
	realizaCadastro: (state,action:any) => { const {realizouCadastro} = action.payload; state.realizouCadastro = realizouCadastro; },
	logout:(state) =>{
		state.nome=null;
		state.logado = false; 
		state.token = null;
		state.realizouCadastro = false;
		state.email = null;
		state.guid = null;
	}
  }
})

export const getLoginState = (state:any) => state[loginState.name]
export const isLogged = (state:any) => state[loginState.name].logado
export const getToken = (state:any) => state[loginState.name].token

export default loginState
