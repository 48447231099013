import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';
import styled from 'styled-components'
import { login } from '../../services/apiCalls';

export default function ModalContent() {
	const history = useHistory();
	const [email,setEmail] = useState('');
	const [error,setError] = useState('');

	const enterAction = (e:any) => {
		if (e.key === 'Enter') {
			doLogin();
		}
	}
	
	async function doLogin(){
		if(!email.includes('@')){
			setError("E-mail inválido")
			return false;
		}
		const res = await login(email);
		if(!res){
			setError("E-mail inválido")
		}
		if(res){
			if(res.status ===1){
				if(res.data && res.data.register_data == "1"){
					history.push('/chat');
				}else{
					history.push('/cadastro');
				}
			}else{
				setError("E-mail inválido")
			}
		}
	}

	return (
		<Container>
			<Title>Informe seu E-mail</Title>
			<RedInput onKeyDown={enterAction} value={email} onChange={(e) => setEmail(e.target.value)} placeholder="E-mail"></RedInput>
			<Button onClick={doLogin}><div>Entrar</div></Button>
			{error && <ErrorMessage><div>{error}</div></ErrorMessage>}
		</Container>
	)
}

const Container = styled.div`
	height:100%;
	max-height:100vh;
	width:100%;
	display:flex;
	flex-direction:column;
	align-items:center;
	justify-content:center;
	color:white;
`
const ErrorMessage = styled.div`
	height:70px;
	color:white;
	display:flex;
	flex-direction:row;
	justify-content:center;
	align-items:center;
	font-size:22px;
	background:linear-gradient(#E31B16 ,#E31B16 ) bottom /* left or right or else */ no-repeat;
	background-size:20% 3px;

`

const RedInput = styled.input`
	color:white;
	background: rgba(20, 20, 20, 0.75);
	border: 1px solid #757575;
	box-sizing: border-box;
	width:100%;
	height: 55px;
	font-size: 16px;
	border-top: 2px solid #E31B16;
	padding-left:30px;
	padding-right:30px;
	max-width:535px;
	margin-bottom:20px;

	::placeholder{
		color:white;	
	}
`

const Title = styled.div`
	font-size:22px;
	padding-bottom:20px;
	text-transform:uppercase;
`

const Button = styled.div`
	width:100%;
	display:flex;
	align-items:center;
	justify-content:center;
	background-color: #E31B16;
	cursor: pointer;
	color:white;
	text-transform:uppercase;
	height: 55px;
	width:100%;
	font-weight:bold;
	max-width:535px;
`