const colors = new Map();
const allColors = [
	'#57D0FD',
	'#95C626',
	'#DC8200',
	'#EB0028',
	'#042F45',
	'#A70061',
	'#82B9E6'
]
export function getColor(guid:any){
	if(colors.has(guid)) return colors.get(guid)
	const newColor = allColors[Math.floor(Math.random() * allColors.length)];
	colors.set(guid,newColor);
	return newColor;
}