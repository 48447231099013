import { useState } from 'react'

export function useTelefoneHandler () {
  const [formatedTelefone, setFormatedTelefone] = useState('')


  function setTelefone (value:any) {
	let telefone = value.replace(/\D+/g, '')
	if(telefone.length > 10){
		const ddd = telefone.slice(0, 2);
		const primeira_parte = telefone.slice(2, 7);
		const segunda_parte = telefone.slice(7, 11);
		telefone = `${ddd} ${primeira_parte}-${segunda_parte}`
	}else if(telefone.length > 6){
		const ddd = telefone.slice(0, 2);
		const primeira_parte = telefone.slice(2, 6);
		const segunda_parte = telefone.slice(6, 10);
		telefone = `${ddd} ${primeira_parte}-${segunda_parte}`
	}else if(telefone.length > 2){
		const ddd = telefone.slice(0, 2);
		const primeira_parte = telefone.slice(2, 6);
		telefone = `${ddd} ${primeira_parte}`
	}
    //const carretpos = event.target.selectionStart + (!hasTraco && telefone.includes('-') ? 1 : 0)
    //setCaretPosition(event.target, carretpos)
    setFormatedTelefone(telefone)
  }

  return {formatedTelefone, setTelefone}
}
