
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { checkChatStatus, getStreaming, loadPrevMessages, sendMessage } from '../../services/apiCalls';
import { getMessageState } from '../../store/slices/messages';
import videoState, { getVideoState } from '../../store/slices/video';
import PlenariaModeloSimples from '../../components/Plenaria/ModeloSimples/PlenariaModeloSimples';
import Spacer from '../../components/Spacer/Spacer';
import Center from '../../components/Center/Center';
import { BottomText, LogoHome } from '../../assets/Images';
import { getLoginState } from '../../store/slices/login';
import { useHistory } from 'react-router-dom';




function Chat() {
	const login = useSelector(getLoginState);
	const history = useHistory();
	useEffect(() => {
		loadPrevMessages(); // carrega mensagens antigas
		getStreaming(); // pega as informações do streaming/video atual
		checkChatStatus(); // pega info se o chat está ou não bloqueado
	}, []);
	
	useEffect(() => {
		window.scrollTo(0,0);
	}, []);

	useEffect(() => {
		if(login.realizouCadastro == "1"){
			history.push("/chat");
		}
	},[login,history])


	const video = useSelector(getVideoState);
	const messages = useSelector(getMessageState);
	const dispatch = useDispatch();
	let [urlReproducao,setUrlReproducao] = useState('');

	useEffect(() => {
		if(!Array.isArray(video.urls) || video.urls.length === 0 )
			setUrlReproducao('');
		else{
			if(video.urls[video.videoAtual]){
				setUrlReproducao(video.urls[video.videoAtual].url)
			}else{
				setUrlReproducao(video.urls[0].url)
			}
		}
	}, [video]) // escolhe qual das urls de video devem ser tocadas

	
	function changeVideo(index:any){ //troca o index do video
		dispatch(videoState.actions.changeIdioma({videoAtual:index}));
	}
	
	return (
		<ContainerPlenaria>
			<Center width={1600}>
			<Topo>
				<LogoHome />
				<BottomText />
			</Topo>
			<PlenariaModeloSimples
				urlReproducao={urlReproducao} //url do video atual
				urls={video.urls} // urls para trocar entre tipos de video
				messages={messages.messages} // mensagens para o chat
				enableChat={video.chat} // se o chat está ativado
				sendMessage={sendMessage} // funcao para enviar mensagem do chat
				changeVideo={changeVideo} // callback que passa o index do video a ser tocado pensando primariamente para multiplos idiomas
			/>
			</Center>
		</ContainerPlenaria>
	);
}

const Topo = styled.div`
	height:150px;
	width:100%;
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	padding-left:30px;
	padding-right:30px;
	
	& img:nth-of-type(1){
		height:140px;
		width:auto;
		padding:30px;
	}

	& img:nth-of-type(2){
		height:140px;
		width:auto;
		padding:20px;
	}

	@media (max-width: 730px) {
		height:100px;
		& img:nth-of-type(1){
			height:100px;
			width:auto;
			padding:30px;
		}

		& img:nth-of-type(2){
			height:100px;
			width:auto;
			padding:20px;
		}
	}


`

const Titulo = styled.div`
	padding-left:20px;
	font-family:ink;
	text-transform:uppercase;
	color:${props => props.theme.red};
	font-size:30px;
	@media (max-width: 730px) {
		text-align:center;
		font-size:20px;
	}
`

const ContainerPlenaria = styled.div`
	height:100%;
	min-height:100%;
	width:100vw;
	max-width:100%;
	min-width:300px;
	display:flex;
	justify-content:center;
	background-image:url('assets/chat_relogio.jpg');
	background-size:contain;
	background-repeat:no-repeat;
	background-position-x:center;
`

const Container = styled.div`
	display:flex;
	flex-grow: 1;
	width:100%;
	min-width:100%;
	height:100%;
	min-height:100vh;
	flex-direction:column;
`

export default Chat;
