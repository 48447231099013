import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { BottomText, LogoHome } from '../../assets/Images'
import Center from '../../components/Center/Center'
import Spacer from '../../components/Spacer/Spacer'
import { useTelefoneHandler } from '../../hooks/useTelefoneHandler'
import { cadastro } from '../../services/apiCalls'
import loginState, { getLoginState } from '../../store/slices/login'

export default function Cadastro() {

	const login = useSelector(getLoginState);
	const history = useHistory();
	const dispatch = useDispatch();

	useEffect(() => {
		if(login.realizouCadastro == "1"){
			history.push("/chat");
		}
	},[login,history])

	const [colaborador,setColaborador] = useState(true);
	const [empresa,setEmpresa] = useState('');
	const [nome,setNome] = useState('');
	const {formatedTelefone,setTelefone} = useTelefoneHandler();
	const [cidade,setCidade] = useState('');
	const [uf,setUF] = useState('');
	const [erroCadastro,setErroCadastro] = useState('');
	const [camposComProblemas,setCamposComProblemas]:any = useState([]);

	async function doCadastro(){	
		const telefone = formatedTelefone.replace(/\D+/g, '');
		const camposComProblemas = [];
		if(empresa.length < 3) camposComProblemas.push("Empresa");
		if(nome.length < 3) camposComProblemas.push("Nome");
		const ufs = ["AC", "AL", "AP", "AM", "BA", "CE", "DF", "ES", "GO", "MA", "MT", "MS", "MG", "PA", "PB", "PR", "PE", "PI", "RJ", "RN", "RS", "RO", "RR", "SC", "SP", "SE", "TO"];
		if (telefone.length !== 10 && telefone.length !== 11) camposComProblemas.push("Telefone");
		if (cidade.length < 3) camposComProblemas.push("Cidade");
		if (!ufs.includes(uf.toUpperCase())) camposComProblemas.push("UF");
		setCamposComProblemas(camposComProblemas);
		const ufEnvio = uf.toUpperCase();

		if (camposComProblemas.length !== 0) {				
			setErroCadastro("Todos os campos são de preenchimento obrigatório.");
			return;
		}
		const res = await cadastro({
			"type":colaborador?"COLABORADOR":"CLIENTE/REVENDEDOR",
			"business":empresa,
			"name":nome,
			"city": cidade,
			"state": ufEnvio,
			"phone": telefone,
		},0);
		console.log(res.status,res.data.status,res)
		if (res) {
			switch (res.status) {
				case 1:
					await dispatch(loginState.actions.realizaCadastro({ realizouCadastro: "1" }));
					return true;
					break;
				case -1:
				case -2:
				case -3:
				case -4:
					await dispatch(loginState.actions.logout());
						history.push("/")
						return;
					break;
				default:
					setErroCadastro("Todos os campos são de preenchimento obrigatório.");
					return;
					break;
			}
		} else {
			await dispatch(loginState.actions.logout());
			history.push("/login");
		}
	}

	return (
		<Center>
			<Container>
				<Spacer height={50} />
				<LogoHome style={{ width: 250, height: 134, marginBottom: 30, zIndex: 2 }} />
				<Linha1>
					<RadioContainer onClick={() => setColaborador(true)}><Radio><RadioCenter className={colaborador?'selected':''} /></Radio> <RadioText> Colaborador</RadioText></RadioContainer>
					<RadioContainer onClick={() => setColaborador(false)}><Radio><RadioCenter className={!colaborador?'selected':''}/></Radio> <RadioText>Cliente/Revendedor</RadioText></RadioContainer>
				</Linha1>
				<Linha2>
					<RedInput className={camposComProblemas.includes("Empresa")?'error':''} value={empresa} onChange={(e) => setEmpresa(e.target.value)} placeholder="Empresa" />
				</Linha2>

				<Linha3>
					<RedInput className={camposComProblemas.includes("Nome")?'error':''} value={nome} onChange={(e) => setNome(e.target.value)} placeholder="Nome" />
					<RedInput className={camposComProblemas.includes("Telefone")?'error':''} value={formatedTelefone} onChange={(e) => setTelefone(e.target.value)} placeholder="Telefone" />
				</Linha3>
				<Linha4>
					<RedInput disabled value={login.email} placeholder="E-mail" />
				</Linha4>
				<Linha5>
					<RedInput className={camposComProblemas.includes("Cidade")?'error':''} value={cidade} onChange={(e) => setCidade(e.target.value)} placeholder="Cidade" />
					<RedInput className={camposComProblemas.includes("UF")?'error':''} maxLength={2} value={uf} onChange={(e) => setUF(e.target.value)} placeholder="UF" />
				</Linha5>
				<Button onClick={doCadastro}><div>Entrar</div></Button>
				{erroCadastro && <ErrorCadastro>
					<div>
						{erroCadastro}
					</div>
				</ErrorCadastro>}
				{!erroCadastro && <Spacer height={50} />}
				<BottomText style={{ width: 150, height: 'auto' }} />
			</Container>
		</Center>
	)
}

const Button = styled.div`
	width:100%;
	display:flex;
	align-items:center;
	justify-content:center;
	background-color: #E31B16;
	cursor: pointer;
	color:white;
	text-transform:uppercase;
	height: 55px;
	width:100%;
	font-weight:bold;
`

const Container = styled.div`
	display:flex;
	flex-direction:column;
	align-items:center;
	flex-wrap:wrap;
	min-width:530px;
	align-self:center;
	@media (max-width: 600px) {
		min-width:90%;
		width:90%;
	}
`
const Linha1 = styled.div`
	display:flex;
	flex-direction:row;
	& > div:first-child {
		margin-right:30px;
	}
	padding-bottom:20px;
	@media (max-width: 600px) {
		flex-direction:column;
		& > div:first-child {
			margin-right:0px;
			margin-bottom:20px;
		}
	}
`

const Linha2 = styled.div`
	width:100%;
	display:flex;
	flex-direction:row;
	padding-bottom:20px;

`

const Linha3 = styled.div`
	display:flex;
	flex-direction:row;
	flex-wrap:wrap;
	align-items:center;
	justify-content:space-between;
	padding-bottom:20px;
	width:100%;
	& > input:first-of-type {
		max-width:290px;
	}
	& > input:nth-of-type(2){
		max-width:220px
	}

	@media (max-width: 600px) {
		& > input:first-of-type {
			max-width:100%;
			margin-bottom:20px;
		}
		& > input:nth-of-type(2){
			max-width:100%
		}
	}
`
const Linha4 = styled.div`
	width:100%;
	display:flex;
	flex-direction:row;
	padding-bottom:20px;
`

const Linha5 = styled.div`
	display:flex;
	flex-direction:row;
	flex-wrap:wrap;
	align-items:center;
	justify-content:space-between;
	padding-bottom:20px;
	width:100%;
	& > input:first-of-type {
		max-width:410px;
	}
	& > input:nth-of-type(2){
		max-width:100px
	}
	@media (max-width: 600px) {
		& > input:first-of-type {
			max-width:100%;
			margin-bottom:20px;
		}
		& > input:nth-of-type(2){
			max-width:100%
		}
	}
`

const RadioContainer = styled.div`
	cursor: pointer;
	display:flex;
	flex-direction:row;
	align-items:center;
`

const RadioText = styled.div`
	color:white;
	text-transform:uppercase;
	font-size:16px;
	padding-left:15px;
`

const Radio = styled.div`
	border-radius:50%;
	border: 1px solid #757575;
	width:36px;
	height:36px;
	padding:8px;
`

const RadioCenter = styled.div`
	width:18px;
	height:18px;
	background-color: #757575;
	opacity: 0.3;
	border-radius:50%;
	&.selected{
		background-color: #E31B16;
		opacity: 1;
	}
`;

const RedInput = styled.input`
	color:white;
	background: rgba(20, 20, 20, 0.75);
	border: 1px solid #757575;
	box-sizing: border-box;
	width:100%;
	height: 55px;
	font-size: 16px;
	border-top: 2px solid #E31B16;
	padding-left:30px;
	padding-right:30px;

	::placeholder{
		color:white;	
	}
	&.error{
		background-color:#E31B1633;
	}
`
const ErrorCadastro = styled.div`
	padding-top:20px;
	padding-bottom:30px;
	min-height:70px;
	color:white;
	display:flex;
	flex-direction:row;
	justify-content:center;
	align-items:center;
	font-size:18px;
	text-align:center;
	font-weight:300;
	max-width:500px;
	background:linear-gradient(#E31B16 ,#E31B16 ) bottom /* left or right or else */ no-repeat;
	background-size:20% 3px;
	@media (max-width: 600px) {
		min-width:90%;
		width:90%;
	}
`