import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { startSocket } from '../../services/socket'
import { getLoginState } from '../../store/slices/login'

export default function Socket() {
	const login = useSelector(getLoginState)
	useEffect(() => {
		startSocket()
	}, [login])
	return (
		<></>
	)
}
