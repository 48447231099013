import React from "react";
import { useSelector } from "react-redux";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from "react-router-dom";
import GuardedRoute from "../components/GuardedRoute/GuardedRoute";
import { getLoginState } from "../store/slices/login";
import Cadastro from "../views/Cadastro/Cadastro";
import Chat from "../views/Home/Chat";
import Login from "../views/Login/Login";

function RouterComponent() {
	const login = useSelector(getLoginState);
	return (
		<>
			<Router>
				<Switch>
					<Route exact path="/">
						<Login />
					</Route>
					<GuardedRoute path="/cadastro" permission={login.token != null} redirect="/">
						<Cadastro />
					</GuardedRoute>
					<GuardedRoute path="/chat" permission={login.token != null} redirect="/">
						<Chat />
					</GuardedRoute>
					<Route path="/">
						<Redirect to="/" />
					</Route>
				</Switch>
			</Router>
		</>
	);
}

export default RouterComponent;
