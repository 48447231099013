import io from 'socket.io-client';
import { chatGUID, socketURL,chatHASH } from '../config';
import store from '../store'
import { getLoginState } from '../store/slices/login';
import messageState from '../store/slices/messages';
import videoState from '../store/slices/video';
import { getStreaming } from './apiCalls';
let socket: any = null;


export function startSocket() {
	const login = getLoginState(store.getState())
	const myGuid = login.guid?login.guid:'XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX';
	
	if (socket) socket.disconnect();
	socket = io(socketURL, { query:`project=${chatHASH}&guid=${myGuid}`, upgrade: false, transports: ['websocket'] });

	socket.on('connect', function (data:any) {
		console.log("connectado");
	});

	// renderiza todas mensagens recebidas (no início da conexão)
	socket.on('notification', function (messages: any) {

	});
	
	socket.on('general', function (message: any) {
		if(message.titulo === "chatStatus"){
			store.dispatch(videoState.actions.changeChat({
				"chat":!!parseInt(message.texto.chat,10),
			}))
		}
		if(message.titulo === "remove_message"){
			store.dispatch(messageState.actions.removeMessage({
				"guid":message.texto.guid_message,
			}))
		}
		if(message.titulo === "chat_new_raw"){
			store.dispatch(messageState.actions.addMessage({
				"guid":message.texto.guid_message,
				"guid_chat":chatGUID,
				"guid_author":message.texto.guid_author,
				"name_author":message.texto.name_author,
				"message":message.texto.message,
				"date":message.data
			}))
		}
		if(message.titulo === "streaming"){
			getStreaming(message.texto);
		}
		
	});

	socket.on('reconnect_attempt', function () {

	});

}

export function stop() {
// 	socket. ('reconnect_attempt', function () {
// 		console.log("reconnect");
// 	});
}