import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { BottomText, LogoHome, MiddleText, RelogioHome } from '../../assets/Images'
import Center from '../../components/Center/Center'
import Modal from '../../components/Modal/Modal'
import Spacer from '../../components/Spacer/Spacer'
import { getKeyVerification } from '../../services/apiCalls'
import loginState from '../../store/slices/login'
import ModalContent from './ModalContent'

export default function Login() {
	const dispatch = useDispatch();
	const [hasError,setHasError] = useState(false);
	const [modalShow,setModalShow] = useState(false);
	const [password,setPassword] = useState('');

	const enterAction = (e:any) => {
		if (e.key === 'Enter') {
			checkKey();
		}
	}

	async function checkKey(){
		const res = await getKeyVerification(password);
		console.log(res);
		if(res && res.status && res.status === 1){
			setModalShow(true);
		}else{
			setHasError(true);
		}
	}
	useEffect(() => {
		dispatch(loginState.actions.logout());
	},[])

	return (
		<>
		<Modal open={modalShow}>
			<ModalContent/>
		</Modal>
		<Center>
			<Container>
				<LogoHome style={{width:250,height:134,marginBottom:30,zIndex:2}} />
				<RelogioHome style={{width:350,height:'auto',marginTop:-80}}/>
				<RedLine />
				<MiddleText style={{maxWidth:450,width:'90%',height:'auto',marginTop:30,marginBottom:50}} />
				<InputContainer>
					<BallInput onKeyDown={enterAction} value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Chave de Acesso"/>
					<Ball onClick={checkKey} />
				</InputContainer>
				{hasError && <ErrorPassword>
					<div>
						Chave de Acesso Inválida!
					</div>
				</ErrorPassword>}
				{!hasError && <Spacer height={40} />}
				<BottomText style={{width:150,height:'auto'}}/>
			</Container>
		</Center>
		</>
	)
}

const Container = styled.div`
	padding-top:50px;
	min-height:100vh;
	display:flex;
	flex-direction:column;
	align-items:center;
	justify-content: center;
	/* background-image:url('assets/login_bg.jpg');
	background-size:contain;
	background-repeat:no-repeat;
	background-position-x:center;
	background-position-y:center; */

`

const ErrorPassword = styled.div`
	font-weight:300;
	height:70px;
	color:white;
	display:flex;
	flex-direction:row;
	justify-content:center;
	align-items:center;
	font-size:22px;
	padding-bottom:10px;
	background:linear-gradient(#E31B16 ,#E31B16 ) bottom /* left or right or else */ no-repeat;
	background-size:20% 3px;
`

const InputContainer = styled.div`
	position:relative;
	max-width:403px;
	width:85%;
`

const Ball = styled.div`
	background-image:url('assets/InputButton3.png');
	position:absolute;
	top:10px;
	right:10px;
	width:39px;
	min-width:39px;
	max-width:39px;
	height:39px;
	min-height:39px;
	max-height:39px;
	background-size:contain;
	background-color:${props => props.theme.red};
	border-radius:50%;
	cursor: pointer;
`


const BallInput = styled.input`
	border: 2px solid #757575;
	border-radius: 30px;
	width: 100%;
	height: 59px;
	font-size:16px;
	padding-left:30px;
	padding-right:80px;
	font-weight:500;
	background-color:black;
	color:#757575;

	::placeholder{
		color:#757575;	
	}
`
const RedLine = styled.div`
	background: #E31B16;
	width: 75px;
	height: 2px;
	z-index: 90;
	margin-top: -118px;
	line-height: 0px;
`