import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Gotham', 'Roboto', 'sans-serif';
    -webkit-tap-highlight-color: transparent;
  }
  *:focus{
    outline:none;
  }
  html{

  }
  body{
	
  }
  #root{
	background-color:${props => props.theme.background};
	display:flex;
	flex-direction: column;
	align-items:center;
	min-height: 100vh;
	min-width:300px;
	flex-grow:1;
  }
  input::placeholder{
    color: ${props => props.theme.placeholder};
  }
`
