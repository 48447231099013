export const emojisList = [
	String.fromCodePoint(0x1F600),
	String.fromCodePoint(0x1F603),
	String.fromCodePoint(0x1F604),
	String.fromCodePoint(0x1F601),
	String.fromCodePoint(0x1F606),
	String.fromCodePoint(0x1F605),
	String.fromCodePoint(0x1F923),
	String.fromCodePoint(0x1F602),
	String.fromCodePoint(0x1F642),
	String.fromCodePoint(0x1F643),
	String.fromCodePoint(0x1F609),
	String.fromCodePoint(0x1F60A),
	String.fromCodePoint(0x1F607),
	String.fromCodePoint(0x1F970),
	String.fromCodePoint(0x1F60D),
	String.fromCodePoint(0x1F929),
	String.fromCodePoint(0x1F618),
	String.fromCodePoint(0x1F617),
	String.fromCodePoint(0x1F61A),
	String.fromCodePoint(0x1F619),
	String.fromCodePoint(0x1F60B),
	String.fromCodePoint(0x1F61B),
	String.fromCodePoint(0x1F61C),
	String.fromCodePoint(0x1F92A),
	String.fromCodePoint(0x1F61D),
	String.fromCodePoint(0x1F911),
	String.fromCodePoint(0x1F917),
	String.fromCodePoint(0x1F92D),
	String.fromCodePoint(0x1F92B),
	String.fromCodePoint(0x1F914),
	String.fromCodePoint(0x1F910),
	String.fromCodePoint(0x1F928),
	String.fromCodePoint(0x1F610),
	String.fromCodePoint(0x1F611),
	String.fromCodePoint(0x1F636),
	String.fromCodePoint(0x1F60F),
	String.fromCodePoint(0x1F612),
	String.fromCodePoint(0x1F644),
	String.fromCodePoint(0x1F62C),
	String.fromCodePoint(0x1F4A8),
	String.fromCodePoint(0x1F925),
	String.fromCodePoint(0x1F60C),
	String.fromCodePoint(0x1F614),
	String.fromCodePoint(0x1F62A),
	String.fromCodePoint(0x1F924),
	String.fromCodePoint(0x1F634),
	String.fromCodePoint(0x1F637),
	String.fromCodePoint(0x1F912),
	String.fromCodePoint(0x1F915),
	String.fromCodePoint(0x1F922),
	String.fromCodePoint(0x1F92E),
	String.fromCodePoint(0x1F927),
	String.fromCodePoint(0x1F975),
	String.fromCodePoint(0x1F976),
	String.fromCodePoint(0x1F974),
	String.fromCodePoint(0x1F635),
	String.fromCodePoint(0x1F92F),
	String.fromCodePoint(0x1F920),
	String.fromCodePoint(0x1F973),
	String.fromCodePoint(0x1F60E),
	String.fromCodePoint(0x1F913),
	String.fromCodePoint(0x1F9D0),
	String.fromCodePoint(0x1F615),
	String.fromCodePoint(0x1F61F),
	String.fromCodePoint(0x1F641),
	String.fromCodePoint(0x2639),
	String.fromCodePoint(0x1F62F),
	String.fromCodePoint(0x1F632),
	String.fromCodePoint(0x1F633),
	String.fromCodePoint(0x1F97A),
	String.fromCodePoint(0x1F626),
	String.fromCodePoint(0x1F627),
	String.fromCodePoint(0x1F628),
	String.fromCodePoint(0x1F630),
	String.fromCodePoint(0x1F625),
	String.fromCodePoint(0x1F622),
	String.fromCodePoint(0x1F62D),
	String.fromCodePoint(0x1F631),
	String.fromCodePoint(0x1F616),
	String.fromCodePoint(0x1F623),
	String.fromCodePoint(0x1F61E),
	String.fromCodePoint(0x1F613),
	String.fromCodePoint(0x1F629),
	String.fromCodePoint(0x1F62B),
	String.fromCodePoint(0x1F971),
	String.fromCodePoint(0x1F624),
	String.fromCodePoint(0x1F621),
	String.fromCodePoint(0x1F620),
	String.fromCodePoint(0x1F92C),
	String.fromCodePoint(0x1F608),
	String.fromCodePoint(0x1F47F),
	String.fromCodePoint(0x1F480),
	String.fromCodePoint(0x2620),
	String.fromCodePoint(0x1F4A9),
	String.fromCodePoint(0x1F921),
	String.fromCodePoint(0x1F479),
	String.fromCodePoint(0x1F47A),
	String.fromCodePoint(0x1F47B),
	String.fromCodePoint(0x1F47D),
	String.fromCodePoint(0x1F47E),
	String.fromCodePoint(0x1F916),
	String.fromCodePoint(0x1F63A),
	String.fromCodePoint(0x1F638),
	String.fromCodePoint(0x1F639),
	String.fromCodePoint(0x1F63B),
	String.fromCodePoint(0x1F63C),
	String.fromCodePoint(0x1F63D),
	String.fromCodePoint(0x1F640),
	String.fromCodePoint(0x1F63F),
	String.fromCodePoint(0x1F63E),
	String.fromCodePoint(0x1F648),
	String.fromCodePoint(0x1F649),
	String.fromCodePoint(0x1F64A),
	String.fromCodePoint(0x1F48B),
	String.fromCodePoint(0x1F48C),
	String.fromCodePoint(0x1F498),
	String.fromCodePoint(0x1F49D),
	String.fromCodePoint(0x1F496),
	String.fromCodePoint(0x1F497),
	String.fromCodePoint(0x1F493),
	String.fromCodePoint(0x1F49E),
	String.fromCodePoint(0x1F495),
	String.fromCodePoint(0x1F49F),
	String.fromCodePoint(0x2763),
	String.fromCodePoint(0x1F494),
	String.fromCodePoint(0x2764),
	String.fromCodePoint(0x1F9E1),
	String.fromCodePoint(0x1F49B),
	String.fromCodePoint(0x1F49A),
	String.fromCodePoint(0x1F499),
	String.fromCodePoint(0x1F49C),
	String.fromCodePoint(0x1F90E),
	String.fromCodePoint(0x1F5A4),
	String.fromCodePoint(0x1F90D),
	String.fromCodePoint(0x1F4AF),
	String.fromCodePoint(0x1F4A2),
	String.fromCodePoint(0x1F4A5),
	String.fromCodePoint(0x1F4AB),
	String.fromCodePoint(0x1F4A6),
	String.fromCodePoint(0x1F4A8),
	String.fromCodePoint(0x1F573),
	String.fromCodePoint(0x1F4A3),
	String.fromCodePoint(0x1F4AC),
	String.fromCodePoint(0x1F5E8),
	String.fromCodePoint(0x1F5EF),
	String.fromCodePoint(0x1F4AD),
	String.fromCodePoint(0x1F4A4),
	String.fromCodePoint(0x1F44B),
	String.fromCodePoint(0x1F91A),
	String.fromCodePoint(0x1F590),
	String.fromCodePoint(0x270B),
	String.fromCodePoint(0x1F596),
	String.fromCodePoint(0x1F44C),
	String.fromCodePoint(0x1F90F),
	String.fromCodePoint(0x270C),
	String.fromCodePoint(0x1F91E),
	String.fromCodePoint(0x1F91F),
	String.fromCodePoint(0x1F918),
	String.fromCodePoint(0x1F919),
	String.fromCodePoint(0x1F448),
	String.fromCodePoint(0x1F449),
	String.fromCodePoint(0x1F446),
	String.fromCodePoint(0x1F595),
	String.fromCodePoint(0x1F447),
	String.fromCodePoint(0x261D),
	String.fromCodePoint(0x1F44D),
	String.fromCodePoint(0x1F44E),
	String.fromCodePoint(0x270A),
	String.fromCodePoint(0x1F44A),
	String.fromCodePoint(0x1F91B),
	String.fromCodePoint(0x1F91C),
	String.fromCodePoint(0x1F44F),
	String.fromCodePoint(0x1F64C),
	String.fromCodePoint(0x1F450),
	String.fromCodePoint(0x1F932),
	String.fromCodePoint(0x1F91D),
	String.fromCodePoint(0x1F64F),
	String.fromCodePoint(0x270D),
	String.fromCodePoint(0x1F485),
	String.fromCodePoint(0x1F933),
	String.fromCodePoint(0x1F4AA),
	String.fromCodePoint(0x1F9BE),
	String.fromCodePoint(0x1F9BF),
	String.fromCodePoint(0x1F9B5),
	String.fromCodePoint(0x1F9B6),
	String.fromCodePoint(0x1F442),
	String.fromCodePoint(0x1F9BB),
	String.fromCodePoint(0x1F443),
	String.fromCodePoint(0x1F9E0),
	String.fromCodePoint(0x1F9B7),
	String.fromCodePoint(0x1F9B4),
	String.fromCodePoint(0x1F440),
	String.fromCodePoint(0x1F441),
	String.fromCodePoint(0x1F445),
	String.fromCodePoint(0x1F444),
	String.fromCodePoint(0x1F476),
	String.fromCodePoint(0x1F9D2),
	String.fromCodePoint(0x1F466),
	String.fromCodePoint(0x1F467),
	String.fromCodePoint(0x1F9D1),
	String.fromCodePoint(0x1F471),
	String.fromCodePoint(0x1F468),
	String.fromCodePoint(0x1F9D4),
	String.fromCodePoint(0x1F469),
	String.fromCodePoint(0x1F9D3),
	String.fromCodePoint(0x1F474),
	String.fromCodePoint(0x1F475),
	String.fromCodePoint(0x1F64D),
	String.fromCodePoint(0x1F64E),
	String.fromCodePoint(0x1F645),
	String.fromCodePoint(0x1F646),
	String.fromCodePoint(0x1F481),
	String.fromCodePoint(0x1F64B),
	String.fromCodePoint(0x1F9CF),
	String.fromCodePoint(0x1F647),
	String.fromCodePoint(0x1F926),
	String.fromCodePoint(0x1F937),
	String.fromCodePoint(0x1F9B0),
	String.fromCodePoint(0x1F9B1),
	String.fromCodePoint(0x1F9B3),
	String.fromCodePoint(0x1F9B2),
	String.fromCodePoint(0x1F435),
	String.fromCodePoint(0x1F412),
	String.fromCodePoint(0x1F98D),
	String.fromCodePoint(0x1F9A7),
	String.fromCodePoint(0x1F436),
	String.fromCodePoint(0x1F415),
	String.fromCodePoint(0x1F9AE),
	String.fromCodePoint(0x1F429),
	String.fromCodePoint(0x1F43A),
	String.fromCodePoint(0x1F98A),
	String.fromCodePoint(0x1F99D),
	String.fromCodePoint(0x1F431),
	String.fromCodePoint(0x1F408),
	String.fromCodePoint(0x1F981),
	String.fromCodePoint(0x1F42F),
	String.fromCodePoint(0x1F405),
	String.fromCodePoint(0x1F406),
	String.fromCodePoint(0x1F434),
	String.fromCodePoint(0x1F40E),
	String.fromCodePoint(0x1F984),
	String.fromCodePoint(0x1F993),
	String.fromCodePoint(0x1F98C),
	String.fromCodePoint(0x1F42E),
	String.fromCodePoint(0x1F402),
	String.fromCodePoint(0x1F403),
	String.fromCodePoint(0x1F404),
	String.fromCodePoint(0x1F437),
	String.fromCodePoint(0x1F416),
	String.fromCodePoint(0x1F417),
	String.fromCodePoint(0x1F43D),
	String.fromCodePoint(0x1F40F),
	String.fromCodePoint(0x1F411),
	String.fromCodePoint(0x1F410),
	String.fromCodePoint(0x1F42A),
	String.fromCodePoint(0x1F42B),
	String.fromCodePoint(0x1F999),
	String.fromCodePoint(0x1F992),
	String.fromCodePoint(0x1F418),
	String.fromCodePoint(0x1F98F),
	String.fromCodePoint(0x1F99B),
	String.fromCodePoint(0x1F42D),
	String.fromCodePoint(0x1F401),
	String.fromCodePoint(0x1F400),
	String.fromCodePoint(0x1F439),
	String.fromCodePoint(0x1F430),
	String.fromCodePoint(0x1F407),
	String.fromCodePoint(0x1F43F),
	String.fromCodePoint(0x1F994),
	String.fromCodePoint(0x1F987),
	String.fromCodePoint(0x1F43B),
	String.fromCodePoint(0x1F428),
	String.fromCodePoint(0x1F43C),
	String.fromCodePoint(0x1F9A5),
	String.fromCodePoint(0x1F9A6),
	String.fromCodePoint(0x1F9A8),
	String.fromCodePoint(0x1F998),
	String.fromCodePoint(0x1F9A1),
	String.fromCodePoint(0x1F43E),
	String.fromCodePoint(0x1F983),
	String.fromCodePoint(0x1F414),
	String.fromCodePoint(0x1F413),
	String.fromCodePoint(0x1F423),
	String.fromCodePoint(0x1F424),
	String.fromCodePoint(0x1F425),
	String.fromCodePoint(0x1F426),
	String.fromCodePoint(0x1F427),
	String.fromCodePoint(0x1F54A),
	String.fromCodePoint(0x1F985),
	String.fromCodePoint(0x1F986),
	String.fromCodePoint(0x1F9A2),
	String.fromCodePoint(0x1F989),
	String.fromCodePoint(0x1F9A9),
	String.fromCodePoint(0x1F99A),
	String.fromCodePoint(0x1F99C),
	String.fromCodePoint(0x1F438),
	String.fromCodePoint(0x1F40A),
	String.fromCodePoint(0x1F422),
	String.fromCodePoint(0x1F98E),
	String.fromCodePoint(0x1F40D),
	String.fromCodePoint(0x1F432),
	String.fromCodePoint(0x1F409),
	String.fromCodePoint(0x1F995),
	String.fromCodePoint(0x1F996),
	String.fromCodePoint(0x1F433),
	String.fromCodePoint(0x1F40B),
	String.fromCodePoint(0x1F42C),
	String.fromCodePoint(0x1F41F),
	String.fromCodePoint(0x1F420),
	String.fromCodePoint(0x1F421),
	String.fromCodePoint(0x1F988),
	String.fromCodePoint(0x1F419),
	String.fromCodePoint(0x1F41A),
	String.fromCodePoint(0x1F40C),
	String.fromCodePoint(0x1F98B),
	String.fromCodePoint(0x1F41B),
	String.fromCodePoint(0x1F41C),
	String.fromCodePoint(0x1F41D),
	String.fromCodePoint(0x1F41E),
	String.fromCodePoint(0x1F997),
	String.fromCodePoint(0x1F577),
	String.fromCodePoint(0x1F578),
	String.fromCodePoint(0x1F982),
	String.fromCodePoint(0x1F99F),
	String.fromCodePoint(0x1F9A0),
	String.fromCodePoint(0x1F490),
	String.fromCodePoint(0x1F338),
	String.fromCodePoint(0x1F4AE),
	String.fromCodePoint(0x1F3F5),
	String.fromCodePoint(0x1F339),
	String.fromCodePoint(0x1F940),
	String.fromCodePoint(0x1F33A),
	String.fromCodePoint(0x1F33B),
	String.fromCodePoint(0x1F33C),
	String.fromCodePoint(0x1F337),
	String.fromCodePoint(0x1F331),
	String.fromCodePoint(0x1F332),
	String.fromCodePoint(0x1F333),
	String.fromCodePoint(0x1F334),
	String.fromCodePoint(0x1F335),
	String.fromCodePoint(0x1F33E),
	String.fromCodePoint(0x1F33F),
	String.fromCodePoint(0x2618),
	String.fromCodePoint(0x1F340),
	String.fromCodePoint(0x1F341),
	String.fromCodePoint(0x1F342),
	String.fromCodePoint(0x1F343),
	String.fromCodePoint(0x1F347),
	String.fromCodePoint(0x1F348),
	String.fromCodePoint(0x1F349),
	String.fromCodePoint(0x1F34A),
	String.fromCodePoint(0x1F34B),
	String.fromCodePoint(0x1F34C),
	String.fromCodePoint(0x1F34D),
	String.fromCodePoint(0x1F96D),
	String.fromCodePoint(0x1F34E),
	String.fromCodePoint(0x1F34F),
	String.fromCodePoint(0x1F350),
	String.fromCodePoint(0x1F351),
	String.fromCodePoint(0x1F352),
	String.fromCodePoint(0x1F353),
	String.fromCodePoint(0x1F95D),
	String.fromCodePoint(0x1F345),
	String.fromCodePoint(0x1F965),
	String.fromCodePoint(0x1F951),
	String.fromCodePoint(0x1F346),
	String.fromCodePoint(0x1F954),
	String.fromCodePoint(0x1F955),
	String.fromCodePoint(0x1F33D),
	String.fromCodePoint(0x1F336),
	String.fromCodePoint(0x1F952),
	String.fromCodePoint(0x1F96C),
	String.fromCodePoint(0x1F966),
	String.fromCodePoint(0x1F9C4),
	String.fromCodePoint(0x1F9C5),
	String.fromCodePoint(0x1F344),
	String.fromCodePoint(0x1F95C),
	String.fromCodePoint(0x1F330),
	String.fromCodePoint(0x1F35E),
	String.fromCodePoint(0x1F950),
	String.fromCodePoint(0x1F956),
	String.fromCodePoint(0x1F968),
	String.fromCodePoint(0x1F96F),
	String.fromCodePoint(0x1F95E),
	String.fromCodePoint(0x1F9C7),
	String.fromCodePoint(0x1F9C0),
	String.fromCodePoint(0x1F356),
	String.fromCodePoint(0x1F357),
	String.fromCodePoint(0x1F969),
	String.fromCodePoint(0x1F953),
	String.fromCodePoint(0x1F354),
	String.fromCodePoint(0x1F35F),
	String.fromCodePoint(0x1F355),
	String.fromCodePoint(0x1F32D),
	String.fromCodePoint(0x1F96A),
	String.fromCodePoint(0x1F32E),
	String.fromCodePoint(0x1F32F),
	String.fromCodePoint(0x1F959),
	String.fromCodePoint(0x1F9C6),
	String.fromCodePoint(0x1F95A),
	String.fromCodePoint(0x1F373),
	String.fromCodePoint(0x1F958),
	String.fromCodePoint(0x1F372),
	String.fromCodePoint(0x1F963),
	String.fromCodePoint(0x1F957),
	String.fromCodePoint(0x1F37F),
	String.fromCodePoint(0x1F9C8),
	String.fromCodePoint(0x1F9C2),
	String.fromCodePoint(0x1F96B),
	String.fromCodePoint(0x1F371),
	String.fromCodePoint(0x1F358),
	String.fromCodePoint(0x1F359),
	String.fromCodePoint(0x1F35A),
	String.fromCodePoint(0x1F35B),
	String.fromCodePoint(0x1F35C),
	String.fromCodePoint(0x1F35D),
	String.fromCodePoint(0x1F360),
	String.fromCodePoint(0x1F362),
	String.fromCodePoint(0x1F363),
	String.fromCodePoint(0x1F364),
	String.fromCodePoint(0x1F365),
	String.fromCodePoint(0x1F96E),
	String.fromCodePoint(0x1F361),
	String.fromCodePoint(0x1F95F),
	String.fromCodePoint(0x1F960),
	String.fromCodePoint(0x1F961),
	String.fromCodePoint(0x1F980),
	String.fromCodePoint(0x1F99E),
	String.fromCodePoint(0x1F990),
	String.fromCodePoint(0x1F991),
	String.fromCodePoint(0x1F9AA),
	String.fromCodePoint(0x1F366),
	String.fromCodePoint(0x1F367),
	String.fromCodePoint(0x1F368),
	String.fromCodePoint(0x1F369),
	String.fromCodePoint(0x1F36A),
	String.fromCodePoint(0x1F382),
	String.fromCodePoint(0x1F370),
	String.fromCodePoint(0x1F9C1),
	String.fromCodePoint(0x1F967),
	String.fromCodePoint(0x1F36B),
	String.fromCodePoint(0x1F36C),
	String.fromCodePoint(0x1F36D),
	String.fromCodePoint(0x1F36E),
	String.fromCodePoint(0x1F36F),
	String.fromCodePoint(0x1F37C),
	String.fromCodePoint(0x1F95B),
	String.fromCodePoint(0x2615),
	String.fromCodePoint(0x1F375),
	String.fromCodePoint(0x1F376),
	String.fromCodePoint(0x1F37E),
	String.fromCodePoint(0x1F377),
	String.fromCodePoint(0x1F378),
	String.fromCodePoint(0x1F379),
	String.fromCodePoint(0x1F37A),
	String.fromCodePoint(0x1F37B),
	String.fromCodePoint(0x1F942),
	String.fromCodePoint(0x1F943),
	String.fromCodePoint(0x1F964),
	String.fromCodePoint(0x1F9C3),
	String.fromCodePoint(0x1F9C9),
	String.fromCodePoint(0x1F9CA),
	String.fromCodePoint(0x1F962),
	String.fromCodePoint(0x1F37D),
	String.fromCodePoint(0x1F374),
	String.fromCodePoint(0x1F944),
	String.fromCodePoint(0x1F52A),
	String.fromCodePoint(0x1F3FA),
	String.fromCodePoint(0x1F30D),
	String.fromCodePoint(0x1F30E),
	String.fromCodePoint(0x1F30F),
	String.fromCodePoint(0x1F310),
	String.fromCodePoint(0x1F5FA),
	String.fromCodePoint(0x1F5FE),
	String.fromCodePoint(0x1F9ED),
	String.fromCodePoint(0x1F3D4),
	String.fromCodePoint(0x26F0),
	String.fromCodePoint(0x1F30B),
	String.fromCodePoint(0x1F5FB),
	String.fromCodePoint(0x1F3D5),
	String.fromCodePoint(0x1F3D6),
	String.fromCodePoint(0x1F3DC),
	String.fromCodePoint(0x1F3DD),
	String.fromCodePoint(0x1F3DE),
	String.fromCodePoint(0x1F3DF),
	String.fromCodePoint(0x1F3DB),
	String.fromCodePoint(0x1F3D7),
	String.fromCodePoint(0x1F9F1),
	String.fromCodePoint(0x1F3D8),
	String.fromCodePoint(0x1F3DA),
	String.fromCodePoint(0x1F3E0),
	String.fromCodePoint(0x1F3E1),
	String.fromCodePoint(0x1F3E2),
	String.fromCodePoint(0x1F3E3),
	String.fromCodePoint(0x1F3E4),
	String.fromCodePoint(0x1F3E5),
	String.fromCodePoint(0x1F3E6),
	String.fromCodePoint(0x1F3E8),
	String.fromCodePoint(0x1F3E9),
	String.fromCodePoint(0x1F3EA),
	String.fromCodePoint(0x1F3EB),
	String.fromCodePoint(0x1F3EC),
	String.fromCodePoint(0x1F3ED),
	String.fromCodePoint(0x1F3EF),
	String.fromCodePoint(0x1F3F0),
	String.fromCodePoint(0x1F492),
	String.fromCodePoint(0x1F5FC),
	String.fromCodePoint(0x1F5FD),
	String.fromCodePoint(0x26EA),
	String.fromCodePoint(0x1F54C),
	String.fromCodePoint(0x1F6D5),
	String.fromCodePoint(0x1F54D),
	String.fromCodePoint(0x26E9),
	String.fromCodePoint(0x1F54B),
	String.fromCodePoint(0x26F2),
	String.fromCodePoint(0x26FA),
	String.fromCodePoint(0x1F301),
	String.fromCodePoint(0x1F303),
	String.fromCodePoint(0x1F3D9),
	String.fromCodePoint(0x1F304),
	String.fromCodePoint(0x1F305),
	String.fromCodePoint(0x1F306),
	String.fromCodePoint(0x1F307),
	String.fromCodePoint(0x1F309),
	String.fromCodePoint(0x2668),
	String.fromCodePoint(0x1F3A0),
	String.fromCodePoint(0x1F3A1),
	String.fromCodePoint(0x1F3A2),
	String.fromCodePoint(0x1F488),
	String.fromCodePoint(0x1F3AA),
	String.fromCodePoint(0x1F682),
	String.fromCodePoint(0x1F683),
	String.fromCodePoint(0x1F684),
	String.fromCodePoint(0x1F685),
	String.fromCodePoint(0x1F686),
	String.fromCodePoint(0x1F687),
	String.fromCodePoint(0x1F688),
	String.fromCodePoint(0x1F689),
	String.fromCodePoint(0x1F68A),
	String.fromCodePoint(0x1F69D),
	String.fromCodePoint(0x1F69E),
	String.fromCodePoint(0x1F68B),
	String.fromCodePoint(0x1F68C),
	String.fromCodePoint(0x1F68D),
	String.fromCodePoint(0x1F68E),
	String.fromCodePoint(0x1F690),
	String.fromCodePoint(0x1F691),
	String.fromCodePoint(0x1F692),
	String.fromCodePoint(0x1F693),
	String.fromCodePoint(0x1F694),
	String.fromCodePoint(0x1F695),
	String.fromCodePoint(0x1F696),
	String.fromCodePoint(0x1F697),
	String.fromCodePoint(0x1F698),
	String.fromCodePoint(0x1F699),
	String.fromCodePoint(0x1F69A),
	String.fromCodePoint(0x1F69B),
	String.fromCodePoint(0x1F69C),
	String.fromCodePoint(0x1F3CD),
	String.fromCodePoint(0x1F6F5),
	String.fromCodePoint(0x1F9BD),
	String.fromCodePoint(0x1F9BC),
	String.fromCodePoint(0x1F6FA),
	String.fromCodePoint(0x1F6B2),
	String.fromCodePoint(0x1F6F4),
	String.fromCodePoint(0x1F6F9),
	String.fromCodePoint(0x1F68F),
	String.fromCodePoint(0x1F6E3),
	String.fromCodePoint(0x1F6E4),
	String.fromCodePoint(0x1F6E2),
	String.fromCodePoint(0x26FD),
	String.fromCodePoint(0x1F6A8),
	String.fromCodePoint(0x1F6A5),
	String.fromCodePoint(0x1F6A6),
	String.fromCodePoint(0x1F6D1),
	String.fromCodePoint(0x1F6A7),
	String.fromCodePoint(0x2693),
	String.fromCodePoint(0x26F5),
	String.fromCodePoint(0x1F6F6),
	String.fromCodePoint(0x1F6A4),
	String.fromCodePoint(0x1F6F3),
	String.fromCodePoint(0x26F4),
	String.fromCodePoint(0x1F6E5),
	String.fromCodePoint(0x1F6A2),
	String.fromCodePoint(0x2708),
	String.fromCodePoint(0x1F6E9),
	String.fromCodePoint(0x1F6EB),
	String.fromCodePoint(0x1F6EC),
	String.fromCodePoint(0x1FA82),
	String.fromCodePoint(0x1F4BA),
	String.fromCodePoint(0x1F681),
	String.fromCodePoint(0x1F69F),
	String.fromCodePoint(0x1F6A0),
	String.fromCodePoint(0x1F6A1),
	String.fromCodePoint(0x1F6F0),
	String.fromCodePoint(0x1F680),
	String.fromCodePoint(0x1F6F8),
	String.fromCodePoint(0x1F6CE),
	String.fromCodePoint(0x1F9F3),
	String.fromCodePoint(0x231B),
	String.fromCodePoint(0x23F3),
	String.fromCodePoint(0x231A),
	String.fromCodePoint(0x23F0),
	String.fromCodePoint(0x23F1),
	String.fromCodePoint(0x23F2),
	String.fromCodePoint(0x1F570),
	String.fromCodePoint(0x1F55B),
	String.fromCodePoint(0x1F567),
	String.fromCodePoint(0x1F550),
	String.fromCodePoint(0x1F55C),
	String.fromCodePoint(0x1F551),
	String.fromCodePoint(0x1F55D),
	String.fromCodePoint(0x1F552),
	String.fromCodePoint(0x1F55E),
	String.fromCodePoint(0x1F553),
	String.fromCodePoint(0x1F55F),
	String.fromCodePoint(0x1F554),
	String.fromCodePoint(0x1F560),
	String.fromCodePoint(0x1F555),
	String.fromCodePoint(0x1F561),
	String.fromCodePoint(0x1F556),
	String.fromCodePoint(0x1F562),
	String.fromCodePoint(0x1F557),
	String.fromCodePoint(0x1F563),
	String.fromCodePoint(0x1F558),
	String.fromCodePoint(0x1F564),
	String.fromCodePoint(0x1F559),
	String.fromCodePoint(0x1F565),
	String.fromCodePoint(0x1F55A),
	String.fromCodePoint(0x1F566),
	String.fromCodePoint(0x1F311),
	String.fromCodePoint(0x1F312),
	String.fromCodePoint(0x1F313),
	String.fromCodePoint(0x1F314),
	String.fromCodePoint(0x1F315),
	String.fromCodePoint(0x1F316),
	String.fromCodePoint(0x1F317),
	String.fromCodePoint(0x1F318),
	String.fromCodePoint(0x1F319),
	String.fromCodePoint(0x1F31A),
	String.fromCodePoint(0x1F31B),
	String.fromCodePoint(0x1F31C),
	String.fromCodePoint(0x1F321),
	String.fromCodePoint(0x2600),
	String.fromCodePoint(0x1F31D),
	String.fromCodePoint(0x1F31E),
	String.fromCodePoint(0x1FA90),
	String.fromCodePoint(0x2B50),
	String.fromCodePoint(0x1F31F),
	String.fromCodePoint(0x1F320),
	String.fromCodePoint(0x1F30C),
	String.fromCodePoint(0x2601),
	String.fromCodePoint(0x26C5),
	String.fromCodePoint(0x26C8),
	String.fromCodePoint(0x1F324),
	String.fromCodePoint(0x1F325),
	String.fromCodePoint(0x1F326),
	String.fromCodePoint(0x1F327),
	String.fromCodePoint(0x1F328),
	String.fromCodePoint(0x1F329),
	String.fromCodePoint(0x1F32A),
	String.fromCodePoint(0x1F32B),
	String.fromCodePoint(0x1F32C),
	String.fromCodePoint(0x1F300),
	String.fromCodePoint(0x1F308),
	String.fromCodePoint(0x1F302),
	String.fromCodePoint(0x2602),
	String.fromCodePoint(0x2614),
	String.fromCodePoint(0x26F1),
	String.fromCodePoint(0x26A1),
	String.fromCodePoint(0x2744),
	String.fromCodePoint(0x2603),
	String.fromCodePoint(0x26C4),
	String.fromCodePoint(0x2604),
	String.fromCodePoint(0x1F525),
	String.fromCodePoint(0x1F4A7),
	String.fromCodePoint(0x1F30A),
	String.fromCodePoint(0x1F383),
	String.fromCodePoint(0x1F384),
	String.fromCodePoint(0x1F386),
	String.fromCodePoint(0x1F387),
	String.fromCodePoint(0x1F9E8),
	String.fromCodePoint(0x2728),
]